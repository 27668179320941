// ** Navigation imports 

import MobileView from './mobileView'
import Sales from './sales'

import Stock from './stock'
// ** Merge & Export

// import Masters from './masters'

// ** Merge & Export
let menus = []
menus = [...menus, ...MobileView]

menus = [...menus, ...Sales]

menus = [...menus, ...Stock]


export default menus

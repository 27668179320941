import { lazy } from 'react'

const LedgerBookReport = lazy(() => import('../../views/accounts/reports/ledgerBook'))

const AccountRoutes = [  
  
  {
    element: <LedgerBookReport />,
    path: '/accounts/reports/ledgerBook'
  }

]

export default AccountRoutes

// ** Icons Import
import { ShoppingBag } from 'react-feather'


export default [
  {
    id: 'report',
    title: 'Ledger Report',
    icon: <ShoppingBag size={12} />,
    navLink: 'accounts/reports/ledgerBook'
  }   
]
